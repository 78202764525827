import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/humbertorocha.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/samuel.jpg" // Tell webpack this JS file uses this image
import pal14 from "../images/palestrantes/bas.png" // Tell webpack this JS file uses this image
import pal15 from "../images/palestrantes/peter.jpg" // Tell webpack this JS file uses this image
import pal16 from "../images/palestrantes/imaculada.jpg" // Tell webpack this JS file uses this image
import pal17 from "../images/palestrantes/eve.jpg" // Tell webpack this JS file uses this image
import pal18 from "../images/palestrantes/mariana.jpg" // Tell webpack this JS file uses this image
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import bullet from "../images/bullet.png" // Tell webpack this JS file uses this image
import alvo from "../images/check.png" // Tell webpack this JS file uses this image

import Footer from "../components/footer"
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/hens1.png" // Tell webpack this JS file uses this image
import logo6 from "../images/logos/hens2.png" // Tell webpack this JS file uses this image
import logo7 from "../images/logos/hens3.png" // Tell webpack this JS file uses this image
import logo8 from "../images/logos/hens4.jpg" // Tell webpack this JS file uses this image
import logo9 from "../images/logos/hens5.png" // Tell webpack this JS file uses this image
import logo10 from "../images/logos/hens6.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import fotoqualidade1 from "../images/fotoscursos/foto-qualidade-carne1.jpg" // Tell webpack this JS file uses this image
import fotoqualidade2 from "../images/fotoscursos/foto-qualidade-carne2.jpg" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Best Practice<br></br> Hens</span> <br></br>
             Economia, implicações e maneio 
na transição para produção<br></br> sem jaulas
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal14} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Bas Rodenburg
                      </div>
                    
                    <div className="titulo inter-light">
                    Especialista em maneio e comportamento de Galinhas Poedeiras
                    <br></br>Utrech Univ.
                      </div>
                      </div>
                    
                  </div>
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal15} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Peter van Horne
                      </div>
                    
                    <div className="titulo inter-light">
                    Economista com foco na Economia do Ovo e tendências de consumo
                    <br></br>Wageningen Univ.
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                        09 de Março de 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Português (tradução)
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      Acesso exclusivo por convite
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/best-practice-hens.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                {/* <a href="https://forms.gle/RdPffFw7DVTYvvmc9" target="_blank" > */}
                {/* <button className="btn-form inter-bold">Inscrição</button>  */}

                <Popup trigger={<button className="btn-form inter-bold"> Inscrições</button>} modal nested>
                  
                  <div>
                    <div className="popup-wrapper">
                      <div className="text">
                        Caso pretenda adquirir um convite, deverá solicitar o mesmo utilizando o email abaixo.
                      </div>
                      <div className="text-email">
                       <a href="mailto: farmin@farmin-trainings.net">farmin@farmin-trainings.net</a> 
                      </div>

                    </div>
                  </div>
                </Popup>


                {/* </a> */}
                </div>

              </div>
            </div>
          </div>
        
      </div>
    </div>
{/* <div id="section-extra">
<div className="wrapper2">
    <div className="col1">

    </div>
    <div className="col2">
      
      </div>
  </div>
  <div className="wrapper">
    <div className="col1">

    </div>
    <div className="col2">
      
      </div>
  </div>
  
</div> */}
    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={logo7} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Tópicos
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc1 inter-light">
            
              Best Practice Hens é um projeto que contribui para ajudar os produtores de ovos a compreender o impacto da transição para sistemas de produção sem jaulas (no solo, ar livre, produção biológica e sistema misto).<br></br><br></br>
Este workshop compõe todo o conhecimento do projeto Best Practice Hens com vista a fornecer orientações sobre as melhores práticas para criação de frangas e galinhas poedeiras adaptadas à realidade de cada país.
<br></br><br></br>
Este workshop será levado a cabo pelo  Economista Prof. Peter van Horne da Universidade de Utrecht (Especialista na economia do consumo de ovo), Bas Rodenburg (especialista em comportamento e maneio de Galinhas poedeiras na Univeridade de Wageningen), Ina Estevez (Pioneira mundial no maneio de Perus e Poedeiras), entre outros…
                </div>
                <div className="desc inter-light">
              <div className="bullet">
              <img src={logo5} alt="" />
              </div>
              <div className="bullet">
              <img src={logo6} alt="" />
              </div>
              <div className="bullet">
              <img src={logo7} alt="" />
              </div>
              <div className="bullet">
              <img src={logo8} alt="" />
              </div>
              <div className="bullet">
              <img src={logo9} alt="" />
              </div>
              <div className="bullet">
              <img src={logo10} alt="" />
              </div>
           
                </div>
        
           
         

                <div className="logos">
                  
                {/* <img src={logo1} alt="" />
                <img src={logo2} alt="" /> */}

                  </div>
            </div>

          
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-publico-alvo">
    <div className="wrapper">
        <div className="wrapper-col-1">
    <div className="wrapper-text">
    <div className="conteudo">
         <div className="title inter-black">
           Temas abordados
           </div>
           

           
           <div className="desc1 inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Em que ponto está a transição para produção sem jaulas na Europa? Vai mesmo acontecer?
           </div>
             </div>
             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Comportamento do consumidor, economia da compra do ovo e marketing 
           </div>
             </div>
             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Compreensão e escolha do tipo de sistema alternativo

           </div>
             </div>
             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Investimento e implicações económicas de cada tipo de produção
           </div>
             </div>
             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Maneio nos diversos tipos de sistema e implicaçõe sanitárias
           </div>
             </div>

             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Boas práticas para garantir a máxima eficiência de postura em sistemas alternativos
           </div>
             </div>

             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Discussão sobre todos os sistemas disponíveis - Custo x Benefício
           </div>
             </div>

      
             </div>
      
     

             <div className="logos">
               
             {/* <img src={logo1} alt="" />
             <img src={logo2} alt="" /> */}

               </div>
         </div>
         </div>
         </div>
    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
        
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 09/03/2023
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:30 - 09:45</span> | <span class="mod">Módulo 1</span> - <span class="txt">Abertura e comunicação institucional (B. Rodenburg, É. Alcázar)
</span></p>
                                </div> 
                                <div class="modulo">
                                <p><span className="date">09:45 - 10:35</span> | <span class="mod">Módulo 2</span> - <span class="txt">Introdução ao projeto Best Practice Hens (B. Rodenburg) <br></br>Como está a decorrer a transição na Europa?
(M. Couto)<br></br>Economia e marketing associados à transição para sistemas de alojamento sem gaiolas (Peter van Horne)
</span></p>
                                </div> 
                                <div class="modulo">
                                <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee-break</span></p>                                </div>
        
                                <div class="modulo">
                                <p><span className="date">11:30 - 12:30</span> | <span class="mod">Módulo 3</span> - <span class="txt">Boas práticas para sistemas de alojamento sem gaiolas (X. Averós)<br></br>Projetar e escolher o sistema de alojamento: características
importantes (B. Rodenburg)<br></br>Maneio, gestão e saúde: inspeção do bando, ferramentas para avaliar a saúde e o bem-estar (I. Estevez)</span></p>
                                </div>

                                <div class="modulo">
                                <p><span className="date">12:30 - 13:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Sessão de discussão: A transição para sistemas de alojamento sem gaiolas é sustentável? (Peter van Horne)<br></br>Conclusão e encerramento (I. Estevez, E.Alcázar)
</span></p>
                                </div>
           
                                <div class="modulo">
                                  <p><span className="date">13:30 - 15:30</span> | <span className="mod">Almoço e Convívio</span> </p>
                                </div>                   
                                </Accordion.Body>

                                </Accordion.Item>
                        

                     

                                            
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/best-practice-hens.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                <div className="form">
                {/* <a href="https://forms.gle/RdPffFw7DVTYvvmc9" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 

                </a> */}
                    <Popup trigger={<button className="btn-form inter-bold"> Inscrições</button>} modal nested>
                  
                  <div>
                    <div className="popup-wrapper">
                      <div className="text">
                        Caso pretenda adquirir um convite, deverá solicitar o mesmo utilizando o email abaixo.
                      </div>
                      <div className="text-email">
                       <a href="mailto: farmin@farmin-trainings.net">farmin@farmin-trainings.net</a> 
                      </div>

                    </div>
                  </div>
                </Popup>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal14} alt="" />

              </div>
              <div className="nome">
              Bas Rodenburg
              </div>
              <div className="titulo">
              Especialista em maneio e comportamento de Galinhas Poedeiras
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
      
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Professor de Avicultura na Universidade de Utrech
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Especialista em maneio e bem-estar em galinhas poedeiras</p>
                
                 
                  </div>
              </div>
            </div>
        </div>
        </div>


        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal15} alt="" />

              </div>
              <div className="nome">
              Peter van Horne
              </div>
              <div className="titulo">
              Economista com foco na Economia do Ovo e tendências de consumo
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Especialista em economia avícola e da produção de ovos - Uni.  Wageningen
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Presidente do grupo de economia da federação europeia da World Poultry
 Science Association
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Economista e analista da Comissão Internacional para a produção 
de ovos (International Egg Commission (IEC))</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Autor de vários artigos na POULTRYWORLD</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Recentemente dedica-se ao impacto económico da transição à produção sem jaulas e 
comportamento do novo consumidor </p>

             
                 
                  </div>
              </div>
            </div>
        </div>
        </div>
        
   
      </div>

      <div className="wrapper-speakers">
      <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal8} alt="" />

              </div>
              <div className="nome">
             Dr. Xavier Averós
              </div>
              <div className="titulo">
              Investigador e especialista em comportamento de aves
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Foi colaborador no INRA (Francia) e na Universidad de Extremadura</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  O seu percurso está relacionado com o desenvolvimento de indicadores de monitorização do comportamento e maneio avicola</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Fez parte do desenvolvimento da certificação de bem-estar em Perus e é formador de auditores</p>
      
  
                 
                  </div>
              </div>
            </div>
        </div>
        </div>


        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal16} alt="" />

              </div>
              <div className="nome">
             Dr. Inmaculada Estevez
              </div>
              <div className="titulo">
              Especialista em comportamento de galinhas poedeiras
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Membro do Comité Científico de Bem-Estar Avícola do
World Poultry Congress

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Escreve e edita no Journal of Poultry Science</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Ajudou a desenvolver o projeto de bem-estar da Perdue Farms (um dos maiores grupos avícolas dos Estados Unidos da America)</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Criou e desenvolveu a aplicação de monitorização de perus I-WATCH-TURKEY®
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Antiga investigadora da Maryland University (EUA), INRA e Univ. da Suécia</p>
                  
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Recipiente de 5 prémios na área avícola a nível mundial
                  </p>

                 
                  
                  </div>
              </div>
            </div>
        </div>
        </div>
        
   
      </div>
      <div className="wrapper-speakers">
      <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal17} alt="" />

              </div>
              <div className="nome">
              Évelyne Marín

              </div>
              <div className="titulo">
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Directora Internacional da ECOVALIA
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Experiência no sector orgânico há mais de 30 anos de experiência</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Gestão e coordenação de projectos internacionais de investigação.</p>
                 
                 
                  </div>
              </div>
            </div>
        </div>
        </div>


        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal18} alt="" />

              </div>
              <div className="nome">
              Mariana Yuan Couto 
              </div>
              <div className="titulo">
              
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Med. Veterinária Especialista em Sistemas de Produção Biológica e técnica de projectos na Ecovalia

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Consultora em gestão holística e em maneio da nutrição em produções extensivas e biológicas

</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Master em Global Health: Integration of Environmental, Human and Animal Health, especialidade Saúde Pública</p>
                 
                  
                  </div>
              </div>
            </div>
        </div>
        </div>
        
   
      </div>

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
